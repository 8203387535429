<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="text-end" style="margin-right: 19px">
      <div class="text-end mt-4" style="margin-right: 19px">
        Visualizzati
        <b v-if="record > 0">
          {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
          -
          {{
            rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
          }}
        </b>
        <b v-else> 0 </b>
        elementi di <b>{{ record }}</b>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        societa_richieste_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="societa_richieste_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsRichiesteUnaSoc"
        @current-change="setCurrentPageRichiesteUnaSoc"
      >
        <template v-slot:cell-stato="{ row: data }"
          ><h1
            class="badge"
            :class="
              data.stato == 'Nuova'
                ? 'badge-light-primary'
                : data.stato == 'Accettata'
                ? 'badge-light-success'
                : data.stato == 'Rifiutata'
                ? 'badge-light-danger'
                : 'badge badge-light'
            "
          >
            {{ data.stato }}
          </h1>
        </template>
        <template v-slot:cell-cod_fisc="{ row: data }">{{
          data.cod_fisc
        }}</template>
        <template v-slot:cell-nominativo_completo="{ row: data }"
          ><div class="tab-long">
            {{ data.nominativo_completo }}
          </div></template
        >
        <template v-slot:cell-tipo_tessera="{ row: data }">
          <div class="tab-long">
            {{ data.tipo_tessera }}

            <span
              v-if="data.premium"
              class="badge text-warning bg-light-warning fs-7"
            >
              Tesserato Gold
            </span>
            <span v-if="data.estiva">
              <Popper arrow content="Tessera estiva">
                <button>
                  <i class="bi bi-brightness-high-fill"></i>
                </button>
              </Popper>
            </span>
          </div>
        </template>
        <template v-slot:cell-tipo_richiesta="{ row: data }">{{
          data.tipo_richiesta
        }}</template>
        <template v-slot:cell-data_richiesta="{ row: data }"
          ><div class="text-center">{{ data.data_richiesta }}</div></template
        >
        <template v-slot:cell-options="{ row: data }">
          <div class="d-flex justify-content-center">
            <button
              v-if="
                data.stato == 'Nuova' &&
                !readOnly &&
                data.id_tipo_tessera != 1 &&
                puo_utilizzare_pulsanti_sezioni
              "
              class="badge -bglight-success rounded fs-5"
              @click="accetta(data.id, societa.id, null, null)"
            >
              <i class="bi bi-check-lg fw-bold text-success"></i>
            </button>
            <template
              v-if="
                data.stato == 'Nuova' &&
                !readOnly &&
                data.id_tipo_tessera == 1 &&
                puo_utilizzare_pulsanti_sezioni
              "
            >
              <button
                v-if="
                  calcoloEtaDaNominativoCompleto(data.nominativo_completo) >= 6
                "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_certificato_medico"
                class="badge -bglight-success rounded fs-5"
                @click="
                  nominativo_completo = data.nominativo_completo;
                  id_richiesta = data.id;
                  extracomunitario = data.extracomunitario;
                "
              >
                <i class="bi bi-check-lg fw-bold text-success"></i>
              </button>
              <button
                v-else
                class="badge -bglight-success rounded fs-5"
                @click="
                  accetta(
                    data.id,
                    societa.id,
                    null,
                    null,
                    data.extracomunitario
                  )
                "
              >
                <i class="bi bi-check-lg fw-bold text-success"></i>
              </button>
            </template>

            <button
              v-if="
                data.stato == 'Nuova' &&
                !readOnly &&
                puo_utilizzare_pulsanti_sezioni
              "
              class="badge bg-light-danger rounded fs-5 ms-4"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_motivazione_rifiuto"
              @click="
                nominativo_completo = data.nominativo_completo;
                id_richiesta = data.id;
              "
            >
              <i class="bi bi-x-lg fw-bold text-danger"></i>
            </button>
            <Popper arrow :content="data.note" v-if="data.stato == 'Rifiutata'">
              <button>
                <i class="bi bi-info-circle-fill text-center"></i>
              </button>
            </Popper>
          </div>
        </template>
      </Datatable>
    </div>
    <ModalMotivazione
      :nominativo_completo="nominativo_completo"
      :id_richiesta="id_richiesta"
      :id_societa="societa.id"
      @getRichiesteUnaSocietaList="$emit('getRichiesteUnaSocietaList')"
    />
    <ModalCertificatoMedico
      :nominativo_completo="nominativo_completo"
      :id_richiesta="id_richiesta"
      :id_societa="societa.id"
      :extracomunitario="extracomunitario"
      @accetta="accetta"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import Popper from "vue3-popper";
import ModalMotivazione from "./ModalMotivazione.vue";
import ModalCertificatoMedico from "./ModalCertificatoMedico.vue";
import isEnabled from "@/composables/isEnabled.js";

import { accettaRichiesta } from "@/requests/richiesteSocietaRequests";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { calcolaEta } from "@/functions/calcoloEtaDaDataNascita";
import { useFormatDateYMDPersoneList } from "@/composables/formatDate";

export default {
  name: "table-richiesta-societa",
  components: {
    Datatable,
    Popper,
    ModalMotivazione,
    Loading,
    ModalCertificatoMedico,
  },
  emits: ["getRichiesteUnaSocietaList", "resetFilters"],
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const isLoading = ref(false);

    const nominativo_completo = ref(null);

    const id_richiesta = ref(null);

    const accetta = (
      id_richiesta,
      id_societa,
      certificato,
      data_certificato,
      isExtraComunitario
    ) => {
      console.log("isExtraComunitario", isExtraComunitario);
      Swal.fire({
        title: "Sei Sicuro?",
        text: "La richiesta verrà accettata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Accetta",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await accettaRichiesta(
            id_richiesta,
            id_societa,
            certificato ? certificato : false,
            data_certificato
          ).then((res) => {
            if (res.status == 200) {
              isExtraComunitario
                ? alertSuccess(
                    '<p style="margin-bottom: .5rem;font-weight: bold; color: #F1416C !important">TESSERA NON ANCORA ATTIVA</p> <p style="margin-bottom: .5rem">Attenzione Tesserato Extracomunitario. </p> Per l\'effettiva emissione della tessera contattare il Comitato Regionale'
                  )
                : alertSuccess("Richiesta accettata correttamente.");
              emit("getRichiesteUnaSocietaList");
              document.getElementById("closeModalCertificato").click();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const setFetchRowsRichiesteUnaSoc = (e) => {
      store.commit("setFetchRowsRichiesteUnaSoc", e);
      emit("getRichiesteUnaSocietaList");
    };
    const setCurrentPageRichiesteUnaSoc = (page) => {
      store.commit("setCurrentPageRichiesteUnaSoc", page);
      emit("getRichiesteUnaSocietaList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnRichiesteUnaSoc", columnName);
      store.commit("setSortOrderRichiesteUnaSoc", order);
      emit("getRichiesteUnaSocietaList");
    };

    const tableHeader = ref([
      {
        name: "Stato",
        key: "stato",
      },
      {
        name: "Codice fiscale",
        key: "cod_fisc",
      },
      {
        name: "Persona",
        key: "nominativo_completo",
      },
      {
        name: "Tipo tessera",
        key: "tipo_tessera",
      },
      {
        name: "Tipo richiesta",
        key: "tipo_richiesta",
      },
      {
        name: "Data richiesta",
        key: "data_richiesta",
        align: "center",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const extracomunitario = ref(0);

    const calcoloEtaDaNominativoCompleto = (nominativo_completo) => {
      const dataNascitaUtc = useFormatDateYMDPersoneList(
        nominativo_completo.substring(
          nominativo_completo.length - 10,
          nominativo_completo.length
        )
      );
      const eta = calcolaEta(dataNascitaUtc);
      return eta;
    };

    return {
      calcoloEtaDaNominativoCompleto,
      extracomunitario,
      societa_richieste_list: computed(() =>
        store.getters.getStateFromName("resultssocieta_richieste_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsocieta_richieste_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordsocieta_richieste_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statussocieta_richieste_list")
      ),

      currentPage: computed(() => store.getters.currentPageRichiesteUnaSoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipRichiesteUnaSoc),
      fetchRows: computed(() => store.getters.fetchRowsRichiesteUnaSoc),
      sortColumn: computed(() => store.getters.sortColumnRichiesteUnaSoc),
      sortOrder: computed(() => store.getters.sortOrderRichiesteUnaSoc),
      setFetchRowsRichiesteUnaSoc,
      setCurrentPageRichiesteUnaSoc,
      setSortOrderColumn,
      tableHeader,

      isLoading,
      accetta,
      nominativo_completo,
      id_richiesta,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
};
</script>

<style scoped>
/* tooltip */
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
